import React, { useEffect, useState } from 'react';
import { auth } from '../../config/firebase';
import { PhoneAuthProvider, RecaptchaVerifier, signInWithCredential, signInWithPhoneNumber } from "firebase/auth";
import {Button, CircularProgress, IconButton, Snackbar, TextField} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import './Auth.css';

function Auth() {
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [verificationId, setVerificationId] = useState('');
    const [isMobile, setIsMobile] = useState(true);
    const [phoneNumber, setPhoneNumber] = useState('+91');
    const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
    const [loading, setLoading] = useState(false); // Added loading state

    useEffect(() => {
        const verifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
            size: 'invisible',
        });

        setRecaptchaVerifier(verifier);

        return () => {
            verifier.clear();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const requestOtp = () => {
        if (!phoneNumber || phoneNumber.length !== 13) {
            showMessage('Please enter a valid 10-digit phone number.');
            return;
        }

        setLoading(true); // Start loader

        signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier)
            .then((confirmationResult) => {
                setVerificationId(confirmationResult.verificationId);
                showMessage('OTP has been sent to your phone.');
            })
            .catch((error) => {
                showMessage(`Failed to send OTP: ${error.message}`);
            })
            .finally(() => {
                setLoading(false); // Stop loader
            });
    };

    const verifyOtp = () => {
        if (!otp || !verificationId) {
            showMessage('Please enter the OTP sent to your phone.');
            return;
        }

        setLoading(true); // Start loader

        const credential = PhoneAuthProvider.credential(verificationId, otp);

        signInWithCredential(auth, credential)
            .then(() => {
                showMessage('Phone number verified successfully!');
            })
            .catch((error) => {
                showMessage(`Failed to verify OTP: ${error.message}`);
            })
            .finally(() => {
                setLoading(false); // Stop loader
            });
    };

    const handlePhoneNumberChange = (e) => {
        const input = e.target.value;

        if (input.startsWith('+91') && input.length <= 13) {
            setPhoneNumber(input);
        }
    };

    const showMessage = (message) => {
        setMessage(message);
        setOpen(true);
    }

    const hideMessage = () => {
        setOpen(false);
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={hideMessage}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    )

    return (
        <div className="container">
            {isMobile ? (
                <div className="auth-content">
                    <h1>Phone Authentication</h1>
                    <div id="recaptcha-container"></div>

                    {!verificationId ? (
                        <div>
                            <h3>Enter Phone Number:</h3>
                            <TextField
                                color="#ffffff"
                                label="Phone Number"
                                variant="outlined"
                                fullWidth={true}
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                                placeholder="+91XXXXXXXXXX"
                                maxLength={13}
                                inputMode="numeric"
                                pattern="[0-9]*"
                                sx={{
                                    mb: 2, // Add some margin-bottom
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',  // Set the text color to white
                                        '& fieldset': {
                                            borderColor: 'white',  // Set the outline color to blue
                                        },
                                        '&:hover fieldset': {
                                            borderColor: 'white',  // Set the hover outline color to blue
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: 'white',  // Set the focused outline color to blue
                                        }
                                    },
                                    '& .MuiInputLabel-root': {
                                        color: 'white',  // Set the label text color to white
                                    },
                                    '& .MuiInputLabel-root.Mui-focused': {
                                        color: 'white',  // Set the label color when focused
                                    }
                                }}
                            />
                            {loading ? (
                                <CircularProgress color="white"/>
                            ) : (
                                <Button variant="contained" onClick={requestOtp} disabled={loading}>Send OTP</Button>
                            )}
                        </div>
                    ) : (
                        <div>
                            <h3>Enter OTP:</h3>
                            <input
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                disabled={loading} // Disable input while loading
                            />
                            {loading ? (
                                <CircularProgress color="white"/>
                            ) : (
                                <button onClick={verifyOtp} disabled={loading}>Verify OTP</button>
                            )}
                        </div>
                    )}

                    {/*{message ? showMessage() : hideMessage()}*/}
                </div>
            ) : (
                <div className="auth-message">
                    This application is only available on mobile devices. Access it on a mobile device.
                </div>
            )}
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={hideMessage}
                message={message}
                action={action}
            />
        </div>
    );
}

export default Auth;
