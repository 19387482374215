import React, {useEffect, useState} from 'react';
import {getAuth, signOut} from 'firebase/auth';
import './Location.css';

function Location() {
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [isMobile, setIsMobile] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile) {
            if ('geolocation' in navigator) {
                const options = {
                    enableHighAccuracy: true,
                    timeout: 10000,
                    maximumAge: 0,
                };

                const watchId = navigator.geolocation.watchPosition(
                    (position) => {
                        setLoading(false);
                        setLatitude(position.coords.latitude.toFixed(6));
                        setLongitude(position.coords.longitude.toFixed(6));
                    },
                    handleError,
                    options
                );

                return () => navigator.geolocation.clearWatch(watchId);
            } else {
                setErrorMessage('Geolocation is not supported by your browser.');
                setLoading(false);
            }
        } else {
            setLoading(false);
            setErrorMessage('Open this website on a mobile device.');
        }
    }, [isMobile]);

    const handleError = (error) => {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                setErrorMessage('Permission denied. Please enable location services.');
                break;
            case error.POSITION_UNAVAILABLE:
                setErrorMessage('Location information is unavailable.');
                break;
            case error.TIMEOUT:
                setErrorMessage('The request to get user location timed out.');
                break;
            default:
                setErrorMessage('An unknown error occurred.');
                break;
        }
        setLoading(false);
    };

    const handleLogout = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                console.log('User logged out successfully');
            })
            .catch((error) => {
                console.error('Error logging out:', error);
            });
    };

    return (
        <div className="location-container">
            {loading ? (
                <div className="location-loader"></div>
            ) : isMobile ? (
                <div className="chips-container">
                    <div className="chip">Latitude: {latitude}</div>
                    <div className="chip">Longitude: {longitude}</div>
                    <div className="logout-button" onClick={handleLogout}>Logout</div>
                </div>
            ) : (
                <div className="location-message">{errorMessage}</div>
            )}
            <footer>Powered by Slashpan</footer>
        </div>
    );
}

export default Location;
