import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import {auth} from './config/firebase';
import Auth from './component/auth/Auth';
import Location from './component/location/Location';

function App() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/auth" element={user ? <Navigate to="/location" /> : <Auth />} />
                <Route path="/location" element={user ? <Location /> : <Navigate to="/auth" />} />
                <Route path="/" element={<Navigate to={user ? "/location" : "/auth"} />} />
            </Routes>
        </Router>
    );
}

export default App;
