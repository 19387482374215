import {getAuth} from "firebase/auth";
import {initializeApp} from "firebase/app";
import {initializeAppCheck, ReCaptchaV3Provider, getToken} from "firebase/app-check";

const app = initializeApp({
    apiKey: "AIzaSyDUGU30B6os9pM5CrYs0eRiBMwCm_HT1EY",
    authDomain: "production-slashpan.firebaseapp.com",
    projectId: "production-slashpan",
    storageBucket: "production-slashpan.appspot.com",
    messagingSenderId: "197129645831",
    appId: "1:197129645831:web:c0c4f88e2ef7b905945fa2",
    measurementId: "G-1J5FZ3ZVL3"
});

const auth = getAuth(app);

auth.settings.appVerificationDisabledForTesting = false;

const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LdicFQqAAAAAHzXbSe-PyK0fPYmsFaY2BKIGjF9'),
    isTokenAutoRefreshEnabled: true
});

getToken(appCheck, true).then((token) => {
    //console.log('App Check Token:', token.token);
});

export { auth }
